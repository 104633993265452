import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Styles from '../app-style.js';
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import PreloadImage from '../helpers/preloadimg'
import {retrievePath, colors} from '../services';

class Yoga extends Component {
  render(){
    return (
      <Grid container item style={{...Styles.fullHt, ...Styles.highZ, ...Styles.whiteBG}} direction="row" alignItems="center" justify="space-between" className="top">
        <Grid item container xs={5} alignItems="flex-start" justify="center" direction="column" style={Styles.paddingL10}>
          <Typography variant="h1" style={{...Styles.marginBottom}}>
            Recruitment time, pruned.<br/> Quality check, done.
          </Typography>                
          <Typography variant="subtitle1">
            We do the heavy lifting for you. <span style={Styles.colorPrimary}>Experienced geeks</span> filtered through a <span style={Styles.colorPrimary}>4-step selection process</span> followed by <span style={Styles.colorPrimary}>176 hours of coding</span> on in-house projects shadowed by our architects. Rest assured, your project is in safe hands.
          </Typography>                
        </Grid>
        <Grid item container xs={5} style={{textAlign: 'right', ...Styles.fullHt, ...Styles.greyBG}} justify="flex-end" direction="column">
          <Grid item container alignItems="flex-end" justify="center">
            <PreloadImage src={`${retrievePath()}gsCoder.png`} alt="Simple, macro-calculated recipes" style={{maxWidth: '80%', textAlign: 'center'}}/>
          </Grid>
          <Grid item style={{...Styles.centerTxt, ...Styles.paddingLR, ...{background: colors.primary}}}>
            <PreloadImage src={`${retrievePath()}process.png`} alt="Simple, macro-calculated recipes" style={{maxWidth: '400px', textAlign: 'center', margin: '0 auto'}}/>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Yoga;
