import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Styles from "../app-style.js";
import { Typography, Fade, Button } from "@material-ui/core";
import { colors } from "../services";
import PreloadImage from "../helpers/preloadimg";
import { retrievePath } from "../services";
import { Link } from "react-router-dom";

class Homeworkoutbanner extends Component {
  render() {
    return (
      <Grid
        item
        container
        style={{
          ...Styles.fullHt,
          ...Styles.highZ,
          ...Styles.fixed,
          ...Styles.greyBG,
          ...Styles.padding5
        }}
        alignItems='flex-end'
        justify='center'
      >
        <Fade in={this.props.section03} timeout={1000}>
          <PreloadImage
            src={`${retrievePath()}technologies.jpg`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: -1
            }}
          />
        </Fade>
        <Grid item xs={7} style={{ textAlign: "center" }}>
          <Typography variant='h1'>Technologies we juggle with</Typography>

          <Button
            variant='outlined'
            color='secondary'
            style={Styles.marginTop}
            onClick={this.props.showDetails}
          >
            <Typography variant='subtitle2'>View all technologies</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default Homeworkoutbanner;
