import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import Styles from "./app-style";
import Banner from "./sections/banner";
import PreloadImage from "./helpers/preloadimg";
import { retrievePath, colors } from "./services";
import Popup from "./sections/popup";
import { Link } from "react-router-dom";
import Homefood from "./sections/homefood";
import Signupform from "./signupform";
class Why extends Component {
  constructor() {
    super();
    this.state = { qualityControl: false, contact: false };
  }
  render() {
    const { qualityControl, contact } = this.state;
    return (
      <React.Fragment>
        <Banner bg='why.jpg'>
          <Grid item xs={4}>
            <Typography variant='h1' style={Styles.marginBottom}>
              Why
            </Typography>
            <Typography variant='subtitle1'>
              Because it's a new era ruled by agility, time-to-market and
              quality. <br />
              Traditional methods need an upgrade.
            </Typography>
          </Grid>
        </Banner>
        <Grid
          item
          container
          style={{ height: "80px", ...Styles.greyBG, ...Styles.highZ }}
          alignItems='stretch'
          justify='flex-start'
        >
          <Grid item xs={4} style={Styles.primaryBG}></Grid>
          <Grid item xs={8} style={Styles.darkBG}></Grid>
        </Grid>
        <Grid
          item
          container
          style={{ ...Styles.whiteBG, ...Styles.highZ }}
          alignItems='stretch'
          justify='flex-start'
        >
          <Grid
            item
            xs={4}
            container
            alignItems='center'
            style={{ ...Styles.whiteBG, ...Styles.padding5 }}
          >
            <Typography variant='subtitle1'>
              Our first project was a chaos.
              <br /> We pushed the timelines by 5 months.{" "}
              <span style={Styles.colorPrimary}>Really</span>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <PreloadImage
              src={`${retrievePath()}chaos.jpg`}
              alt='IT project getting delayed?'
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{ ...Styles.highZ, ...Styles.greyBG }}
          alignItems='stretch'
          justify='flex-start'
        >
          <Grid item xs={4}>
            <PreloadImage
              src={`${retrievePath()}solution.jpg`}
              alt='a solution to all the IT outsourcing challenges'
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={8} style={{ ...Styles.whiteBG, ...Styles.padding10 }}>
            <Typography variant='subtitle2'>
              <span style={Styles.colorPrimary}>Our story.</span> Confession: it
              was an in-house project and GeekSquad didn't exist at the time.
              The project began with major challenges - acquiring quality
              resources, strenous interviews and months of notice period. No
              prize for guessing - the project was delayed. There were raised
              eye-brows all around us. However, the quality of the finished
              product turned the frown upside-down. Second phase was a different
              monster. Team occupancy, hiring, budget and timelines posed as
              primary concerns.
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginTop}>
              Desperate times call for desperate measures. We decided to build
              and manage a remote team. Suddenly, the world was our canvas. We
              acquired the best talent in the industry in just a week's time. It
              worked like a charm. Post the stablization of the project, we
              released 60% of the team. Nobody complained.
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginTop}>
              That ws our light bulb moment! We realised that
              <span style={Styles.colorPrimary}>
                {" "}
                every company needs experts and they need them now.
              </span>{" "}
              Being able to release or hire them on-demand is a bonus.
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginTop}>
              And thus, GeekSquad was born!
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{ ...Styles.highZ, ...Styles.whiteBG, ...Styles.padding10 }}
          alignItems='center'
          justify='center'
          direction='column'
        >
          <Typography
            variant='subtitle2'
            style={{ ...Styles.colorPrimary, ...Styles.marginBottom }}
          >
            Why work with us?
          </Typography>
          <Typography variant='subtitle1'>
            Because, we are the largest source for curated, ready to hire,
            super-high-end developers in our niche
          </Typography>
        </Grid>
        <Grid
          item
          container
          style={{
            ...Styles.greyBG,
            ...Styles.highZ,
            ...Styles.padding5,
            ...Styles.marginBottom,
            ...Styles.marginTop
          }}
          justify='space-between'
        >
          <Grid item container xs={6} alignItems='center'>
            {/* <PreloadImage src={`${retrievePath()}team.png`} alt="Your personal, fully managed remote IT team" style={{...Styles.marginBottom, ...{width: '95%', marginTop: '-10%'}}}/> */}
            <PreloadImage
              src={`${retrievePath()}pnp.png`}
              alt='Your personal, fully managed remote IT team'
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography variant='subtitle2' style={Styles.marginBottom}>
              But more importantly ...
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginBottom}>
              We provide you a completely managed remote team.{" "}
              <span style={Styles.colorPrimary}>Zero hassle</span>. Only the
              best talent from across the globe.
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginBottom}>
              We are a{" "}
              <span style={Styles.colorPrimary}>plug-and-play team</span>. Plug
              us into an ongoing project or use our expert geeks to architect a
              new application. We are game
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginBottom}>
              We are <span style={Styles.colorPrimary}>your in-house team</span>
              . We attend daily team calls, update user stories on your project
              tracking tool, share daily reports and timesheets, and we don't
              sweat over change requests.
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginBottom}>
              We care about your project. Our{" "}
              <span
                style={{
                  ...Styles.colorPrimary,
                  ...Styles.underline,
                  ...Styles.cursorPointer
                }}
                variant='outlined'
                color='primary'
                onClick={() => {
                  this.setState({ qualityControl: true });
                }}
              >
                6-D quality control
              </span>{" "}
              ensures your project works in full-throttle at all times.
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginBottom}>
              We value your money. Release one or more or all the members of the
              team whenever your work is done.{" "}
              <span
                style={{
                  ...Styles.colorPrimary,
                  ...Styles.underline,
                  ...Styles.cursorPointer
                }}
                variant='outlined'
                color='primary'
                onClick={() => {
                  this.setState({ contact: true });
                }}
              >
                Talk to us today
              </span>{" "}
              to know how.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          style={{
            ...Styles.darkBG,
            ...Styles.marginLR,
            ...{ height: "60vh", width: "80%", marginTop: "100px" },
            ...Styles.marginBottom
          }}
        >
          <Grid
            item
            container
            alignItems='center'
            justify='center'
            xs={7}
            style={{ position: "relative" }}
          >
            <PreloadImage
              src={`${retrievePath()}bigLogo.png`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            />
            <Typography variant='subtitle1' style={{ color: colors.base }}>
              One year journey ...
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction='column'
            alignItems='center'
            justify='center'
            xs={4}
          >
            <Typography variant='h1' style={{ color: colors.base }}>
              150+
            </Typography>
            <Typography
              style={{ ...{ color: colors.base }, ...Styles.marginBottom }}
            >
              curated developers
            </Typography>
            <Typography variant='h1' style={{ color: colors.base }}>
              240,000+
            </Typography>
            <Typography
              style={{ ...{ color: colors.base }, ...Styles.marginBottom }}
            >
              coding hours
            </Typography>
            <Typography variant='h1' style={{ color: colors.base }}>
              80,000+
            </Typography>
            <Typography style={{ color: colors.base }}>
              user storiess delivered
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems='center'
          justify='center'
          style={Styles.marginTop}
          direction='column'
        >
          <Typography
            variant='subtitle2'
            style={{ ...{ color: colors.primary } }}
          >
            You're probably thinking
          </Typography>
          <Typography
            variant='subtitle1'
            style={{ ...Styles.marginTop, ...Styles.marginBottom }}
          >
            <strong>
              Sounds cool but remote team dosn't really work, does it?
            </strong>
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems='center'
          justify='center'
          style={Styles.marginTop}
        >
          <Grid item xs={4} style={Styles.padding5}>
            <Typography variant='subtitle2' style={{}}>
              You're right. Though the advantages of remote team outweigh the
              disadvantages, it has it's own set of hassles. That's why we
              provide you{" "}
              <span style={Styles.colorPrimary}>'Managed remote teams'</span>.
              Think of it as a remote team on steroids!
            </Typography>
            <Typography
              variant='subtitle2'
              style={{ ...Styles.marginTop, ...Styles.marginBottom }}
            >
              Wish to know more?
            </Typography>
            <Link
              to='contact'
              style={{ ...Styles.colorPrimary, ...Styles.cursorPointer }}
            >
              <Typography variant='subtitle2'>
                <span style={Styles.underline}>Talk to us</span>
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={8} style={Styles.padding5}>
            <PreloadImage
              src={`${retrievePath()}doubt.jpg`}
              alt='IT project getting delayed?'
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        {qualityControl && (
          <Popup
            showPopup={qualityControl}
            hidePopup={() => {
              this.setState({ qualityControl: false });
            }}
          >
            <Homefood />
          </Popup>
        )}
        {contact && (
          <Popup
            showPopup={contact}
            hidePopup={() => {
              this.setState({ contact: false });
            }}
          >
            <Grid
              item
              container
              style={{ paddingTop: "15vh" }}
              justify='center'
            >
              <Signupform />
            </Grid>
          </Popup>
        )}
      </React.Fragment>
    );
  }
}
export default Why;
