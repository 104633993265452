import { colors } from "./services";
export default {
  colorWhite: {
    color: colors.secondary
  },
  colorBase: {
    color: colors.base
  },
  colorPrimary: {
    color: colors.primary
  },
  colorGrey: {
    color: colors.grey
  },
  primaryBG: {
    backgroundColor: colors.primary
  },
  whiteBG: {
    backgroundColor: colors.base
  },
  darkBG: {
    backgroundColor: colors.secondary
  },
  greyBG: {
    backgroundColor: colors.greyBG
  },
  smallBtn: { padding: "2px 8px" },
  underline: { borderBottom: `1px dotted ${colors.primary}` },
  smallLogo: {
    position: "fixed",
    top: "20px",
    left: "50px",
    width: "30px",
    height: "30px"
  },
  activeLink: {
    color: colors.primary,
    fontWeight: "800"
  },
  marginTop: {
    marginTop: "10px"
  },
  padding10: {
    padding: "10%"
  },
  padding5: {
    padding: "5%"
  },
  padding1: {
    padding: "1%"
  },
  paddingTop: {
    paddingTop: "10%"
  },
  paddingLR: {
    paddingLeft: "5%",
    paddingRight: "5%"
  },
  paddingTB: {
    paddingTop: "13%",
    paddingBottom: "13%"
  },
  paddingRight5: {
    paddingRight: "5px"
  },
  paddingL10: {
    paddingLeft: "10%"
  },
  spacingVertical: {
    padding: "100px 0"
  },
  centerTxt: {
    textAlign: "center"
  },
  leftTxt: {
    textAlign: "left"
  },
  cursorPointer: {
    cursor: "pointer"
  },
  link: {
    color: colors.primary,
    textDecoration: "none"
  },
  banner: {
    minHeight: "100vh",
    zIndex: 1000,
    overflow: "hidden"
  },
  fixed: {
    position: "sticky",
    top: 0
  },
  section01: {
    color: colors.secondary,
    minHeight: "100vh",
    zIndex: 500,
    background: colors.base
  },
  section01Content: {
    background: colors.greyBG,
    padding: "5%",
    textAlign: "center"
  },
  marginBottom: {
    marginBottom: "50px"
  },
  marginLR: {
    margin: "0 10%"
  },
  section02: {
    background: colors.greyBG,
    padding: "0 5%",
    minHeight: "100vh"
  },
  translucentContainer: {
    background: colors.translucentBG,
    padding: "20px"
  },
  fullHt: {
    minHeight: "100vh",
    background: colors.greyBG
  },
  fullHtNoBG: { minHeight: "100vh" },
  highestZ: {
    zIndex: 20000
  },
  higherZ: {
    zIndex: 10001
  },
  highZ: {
    zIndex: 10000
  },
  imgWidth: {
    width: "100%"
  },
  header: {
    position: "fixed",
    top: "10px",
    left: 0,
    padding: "10px 20px",
    zIndex: 20000
  },
  popup: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    paddingTop: "120px",
    width: "100%",
    background: colors.base
  },
  footer: {
    padding: "50px",
    background: colors.base,
    textAlign: "center"
  },
  //form styles
  formFieldContainer: {
    height: "85px",
    overflow: "hidden",
    marginBottom: "10px"
  },
  whiteColor: { color: colors.secondary },
  err: { color: colors.err },
  special: { display: "inline-block", width: "5%", textAlign: "center" },
  countryContainer: { display: "inline-block", width: "15%" },
  mobileContainer: { display: "inline-block", width: "75%" },
  contactAvatar: {
    borderRadius: "25px",
    width: "50px",
    height: "50px"
  },
  /*Game styles*/
  flipCard: {
    width: "120px",
    height: "120px",
    textAlign: "center",
    padding: "10px"
  }
};
