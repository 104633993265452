import React, { Component } from "react";
import Header from "./sections/header";
import Footer from "./sections/footer";
import ScrollToTop from "./scrolltotop";
import { ThemeProvider, StylesContext } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import theme from "./theme";
// import PreloadImage from './helpers/preloadimg';
// import {retrievePath} from './services';
// import Styles from './app-style.js';
// import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./home";
// import Package from './package';
// import Ordersummary from './ordersummary';
// import ReactPixel from 'react-facebook-pixel';
// import Secret from './secretworkout';
import Tech from "./tech";
import Why from "./why";
import How from "./how";
import Contact from "./contact";
import Game from "./Game/Game";
// ReactGA.initialize('UA-86957619-1');
// ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.pageview('/');
// ReactGA.pageview('/packages');
// ReactGA.pageview('/secret');
// ReactGA.pageview('/summary');
// const options = {
//   autoConfig: true, 	// set pixel's autoConfig
//   debug: false, 		// enable logs
// };

class App extends Component {
  constructor() {
    super();
    this.state = {
      scrollStatus: false
    };
  }

  render() {
    let { scrollStatus } = this.state;
    return (
      <Router>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <Grid
              container
              direction='column'
              className='app'
              style={
                scrollStatus ? { overflow: "hidden", height: "100vh" } : {}
              }
            >
              <Header />
              <Switch>
                <Route path='/' exact>
                  <Home
                    freezeScroll={val => {
                      this.freezeScroll(val);
                    }}
                  />
                </Route>
                <Route path='/game' exact>
                  <Game />
                </Route>
                <Route path='/tech'>
                  <Tech />
                </Route>
                <Route path='/why'>
                  <Why />
                </Route>
                <Route path='/how'>
                  <How />
                </Route>
                <Route path='/contact' component={Contact} />

                {/* <Route path="/secret" component = {Secret}/>
                  <Route path="/packages">
                    <Package freezeScroll={(val)=>{this.freezeScroll(val)}}/>
                  </Route>
                  <Route path="/thank_you/summary/:orderId" component = {Ordersummary}/> */}
              </Switch>
              <Footer />
            </Grid>
          </CssBaseline>
        </ThemeProvider>
      </Router>
    );
  }
}
export default App;
