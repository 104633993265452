import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Styles from "./app-style.js";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import { colors } from "./services";
// import PreloadImage from '../helpers/preloadimg'
class Faq extends Component {
  render() {
    return (
      <Grid
        item
        container
        style={{
          ...Styles.highZ,
          ...Styles.paddingTop,
          ...Styles.marginBottom
        }}
        justify='space-evenly'
        alignItems='center'
        className='top'
      >
        <Grid item xs={5}>
          <Typography variant='h1' style={Styles.marginBottom}>
            Frequently asked questions ...
          </Typography>
          <Typography variant='subtitle1'>
            We are often confused with regular placement agencies and services
            like Freelancer.com and Upwork. While we might appear similar on the
            surface, we are as different as day and night.{" "}
            <span style={{ color: colors.primary }}>
              Here are the common list of questions. For any further queries,
              feel free to contact us.
            </span>
          </Typography>
        </Grid>
        {/* <Grid item xs={5}>
          <PreloadImage src={`${retrievePath()}recipe.jpg`} alt="Simple, macro-calculated recipes" style={Styles.imgWidth}/>
        </Grid> */}
        <Grid item xs={5}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className='material-icons'>add</span>}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='subtitle1'>
                How are you different from freelancer.com?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant='subtitle2'>
                In freelancer.com, you post a requirement, receive biddings and
                you choose a resource. There is no quality control. Besides, you
                choose individuals and not teams. With GeekSquad, you work with
                our pre-recruited, in-house geeks that are the best in the
                industry. You can recruit a single resource or a complete team.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className='material-icons'>add</span>}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='subtitle1'>
                How are you different from Upwork?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant='subtitle2'>
                Upwork is a marketplace. You will get thousands of profiles to
                choose from. Their pruning starts after you post a requirement.
                We, on the other hand, provide you pre-recruited, in-house
                geeks. We deal with niche technologies and provide you choicest
                set of developers who are super-specialists in their domain.
                Each geek comes with a proven track-record of large-scale
                projects and not less than 4 years of experience.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className='material-icons'>add</span>}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='subtitle1'>
                How are you different from project outsourcing?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant='subtitle2'>
                Outsourcing a project has numerous hassles. From completely
                freezing the scope to re-negotiating change requests, it kills
                the idea of creating a great project and focuses more on time
                limitations and pricing. We, on the other hand, provide you a
                team. Work with them in phases, target bi-monthly or monthly
                release, come up with change requests. Our geeks will not fret
                because during the engagement time, they are your in-house team.
                Feel free to engage with them as long as you need and release as
                soon as your project is stablised.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className='material-icons'>add</span>}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='subtitle1'>
                How are you different from any other agencies?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant='subtitle2'>
                We are a company managed by senior software architects. We have
                technology in our DNA. We understand the importance of scalable,
                re-usable codes and follow the most stringent means of
                recruitment. To ensure quality, we restrict ourselves to a very
                limited set of technologies and work with only experienced,
                highly qualified coders in the industry.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className='material-icons'>add</span>}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='subtitle1'>
                What do you mean by pre-recuited resources?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant='subtitle2'>
                Each one of our geek is recruited using a 5 step process: Expert
                test, Hackathon, Rapid coding, Interview followed by 176 hours
                of coding on in-house, large scale projects. Background
                verification is done for each one of them.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className='material-icons'>add</span>}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='subtitle1'>
                What is a completely-managed remote team?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant='subtitle2'>
                At a nominal extra cost, we assign a super-geek to your project
                who is trained in project management. Our 'technical manager'
                plans the project sprints with your project head and manages our
                remote team with daily standups and reporting to make sure every
                delivery commitment is spot-on. What's more? They are not an
                overhead because in addition to managing, they also write great
                codes for your project.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export default Faq;
