import React from "react";
import Styles from "../app-style.js";
import { Grid, Typography } from "@material-ui/core";
export default () => {
  return (
    <Grid
      item
      container
      style={{
        ...Styles.fullHtNoBG,
        ...{
          width: "100%",
          top: 0,
          left: 0,
          position: "absolute",
          background: "rgba(0,0,0,.1)",
          zIndex: 1000
        }
      }}
      justify='center'
      alignItems='center'
    >
      <Grid
        item
        style={{ background: "RGBA(255, 255, 255, .5)", padding: "10px" }}
      >
        <Typography variant='h2'>Watch the pairs of geeks carefully</Typography>
      </Grid>
    </Grid>
  );
};
