import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Styles from '../app-style.js';
import { Typography} from '@material-ui/core';
import {colors} from '../services';

class Feelgood extends Component {
  render(){
    return (
      <Grid item container className={{"fixed": true, "top": true}} style={{...Styles.section01, ...Styles.highZ,  ...Styles.greyBG}} alignItems="center" justify="space-evenly">
          <Grid item container xs={3} style={Styles.marginBottom}>
            <span className="material-icons" style={{fontSize: '8em', transform: 'rotate(180deg)', marginLeft: '-21px', color: colors.primary}}>
              format_quote
            </span>
            <Typography variant="h1">
              We know your project needs experts and it needs them 'yesterday'
            </Typography>
          </Grid>          
          <Grid item xs={5}>
            <Typography variant="subtitle1">
              Full-stack developers are indispensable. But recruiting them is a nightmare. Majority don’t meet the production standards. Good ones have abundant offers. Notice period delays the project and attrition-rate is atrocious!
              </Typography>
              <Typography variant="subtitle1" style={Styles.marginTop}>
                In-comes GeekSquad – a company with more than <span style={{color: colors.primary}}>150 brilliant curated developers</span> and software architects that are ready-for-deployment. With <span style={{color: colors.primary}}>240,000+ coding hours</span> and more than <span style={{color: colors.primary}}>80,000 user-stories</span> to our credit, we are easily the best fit for your project. We work with technologies ranging from React, Angular to Node, MongoDB and AWS serverless architecture.
            </Typography>
          </Grid>
            {/* <Grid item xs={6}>
              <Typography variant="h2" style={{...{color: colors.grey, textAlign: 'left'}, ...Styles.padding5}}>
                Optimize your immunity with a <span style={Styles.colorPrimary}>personalized nutrition plan</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h2" style={{...{color: colors.grey, textAlign: 'left'}, ...Styles.padding5}}>
                <span style={Styles.colorPrimary}>Workout at home</span> with do-it-along videos
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h2" style={{...{color: colors.grey, textAlign: 'left'}, ...Styles.padding5}}>
                Come back for a fun, <span style={Styles.colorPrimary}>live evening session</span>
              </Typography>
            </Grid> */}
      </Grid>
    );
  }
}

export default Feelgood;
