import React, { Component } from "react";
import "./App.css";
import GetStarted from "./sections/getstarted";
import Feelgood from "./sections/feelgood";
import Banner from "./sections/banner";
import Yoga from "./sections/yoga";
import Yogabanner from "./sections/yogabanner";
import Homeworkoutbanner from "./sections/homeworkoutbanner";
import Homeworkout from "./sections/homeworkout";
import Diet from "./sections/diet";
import Homefood from "./sections/homefood";
import Popup from "./sections/popup";
import Fun from "./sections/fun";
import { Typography, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Styles from "./app-style.js";
import { docHt } from "./services";
import Manager from "./sections/manager";
import { Link } from "react-router-dom";
import Tech from "./tech";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlaying: false,
      showPopup: false,
      section02: false,
      section03: false
    };
  }

  handleScroll(e) {
    let scrollTop = window.scrollY;
    let { section02, section03 } = this.state;
    if (scrollTop >= docHt() * 1.5) {
      if (!section02) this.setState({ section02: true });
    } else if (section02) {
      this.setState({ section02: false });
    }
    if (scrollTop >= 3.5 * docHt()) {
      if (!section03) this.setState({ section03: true });
    } else if (section03) {
      this.setState({ section03: false });
    }
  }
  componentDidMount() {
    // updateLoc(this.cb);
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }
  render() {
    const { showPopup, section02, section03 } = this.state;
    return (
      <React.Fragment>
        <Banner bg='softwarecompany.jpg' fixed={true}>
          <Grid item container xs={8} style={{ ...Styles.marginBottom }}>
            <Typography variant='h1'>
              Struggling to find a team of awesome full-stack experts?
            </Typography>
            <Typography
              variant='subtitle1'
              style={{ ...Styles.marginTop, ...Styles.marginBottom }}
            >
              Save your time and money. Deploy a fully-managed remote team today
              from our <br />
              meticulously recruited, time-tested squad of geeks
            </Typography>
            <Link to='contact'>
              <Button variant='contained' color='primary'>
                <Typography variant='subtitle2'>
                  Fast track your project &nbsp;
                </Typography>
                <span className='material-icons'>arrow_forward</span>
              </Button>
            </Link>
          </Grid>
        </Banner>
        <Feelgood />
        <Yogabanner section02={section02} />
        <Yoga />
        <Diet />
        <Homeworkout />
        <Homefood />
        <Fun />
        <Homeworkoutbanner
          section03={section03}
          showDetails={() => {
            this.setState({ showPopup: true });
          }}
        />
        <GetStarted />
        {showPopup && (
          <Popup
            showPopup={showPopup}
            hidePopup={() => {
              this.setState({ showPopup: false });
            }}
          >
            <Tech />
          </Popup>
        )}
      </React.Fragment>
    );
  }
}

export default Home;
