import Axios from "axios";
// import CryptoJS from 'crypto-js';
// import aes from 'crypto-js/aes';
// import encHex from 'crypto-js/enc-hex';
// import padZeroPadding from 'crypto-js/pad-zeropadding';
import config from "./config";
// let secret_key = encHex.parse(config.secret);
// let secret_iv = encHex.parse(config.iv);

function docHt() {
  return document.documentElement.clientHeight;
}
function s3GlobalPath(name, type) {
  return `https://gsg-image-uploads.s3-accelerate.amazonaws.com/webcontent/${type}/${name}`;
}
function retrievePath() {
  return "https://geeksquad-imgs.s3.ap-south-1.amazonaws.com/";
}
let colors = {
  primary: "#ee0000",
  secondary: "#1E2022",
  err: "red",
  grey: "#555",
  base: "#fff",
  greyBG: "#F6F7F8",
  translucentBG: "RGBA(242,242,242,.5)"
};
function get(key) {
  return localStorage.getItem(key);
}
function set(key, value) {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  return localStorage.setItem(key, value);
}
function getURL(url) {
  return `https://api.getsetgo.fitness/base_ind/API/v1/${url}`;
}
function updateLoc(cb) {
  if (!(get("loc") && get("products") && get("offer"))) {
    callAPI(
      "https://ipinfo.io/?token=22065f6a076bdf",
      "get",
      data => {
        changeLocation(data, cb);
      },
      err => {
        askLocation(err);
      }
    );
  }
  // changeLocation({});
}
function changeLocation(data, cb) {
  data = data.data;
  let locObj = {
    ip: data.ip,
    city: data.city,
    region: data.region,
    country: data.country
  };
  set("loc", locObj);
  let region = "row";
  let AE = [
    "AE",
    "OM",
    "QA",
    "SA",
    "BH",
    "KW",
    "TR",
    "SY",
    "IR",
    "EG",
    "IQ",
    "IL",
    "YE",
    "LB",
    "JO",
    "PS",
    "CY",
    "IR"
  ];
  let IN = ["IN", "NP", "LK", "BT", "MM", "PK", "BD", "AF", "MV"];
  if (AE.indexOf(locObj.country) >= 0) region = "ae";
  if (IN.indexOf(locObj.country) >= 0) region = "in";
  // let entryptedRegion = encryption(`${region}|${(new Date(Date.now()))}`);
  // console.log("entryptedRegion",`${region}|${(new Date(Date.now()))}`, entryptedRegion);
  // callAPI(`https://api.getsetgo.fitness/base_ind/API/v1/user_packages/${entryptedRegion}`,'get',(data)=>{console.log(data)}, (err)=>{console.log(err)});
  set("products", config[region]);
  set("offer", config["offer"]);
  cb(get("products").currency);
}
function askLocation(err) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(setLoc);
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
}
function setLoc(position) {
  console.log(position);
}
// function encryption(dataToBeEncrypted) {
//     let encrypted_string = '';
//     encrypted_string = ((aes.encrypt(dataToBeEncrypted, secret_key, { iv: secret_iv}).toString()).replace(/\//g, '_')).replace(/\+/g, '-');
//     return encrypted_string;
//   }
function callAPI(url, type, success, error, data) {
  switch (type) {
    case "get":
      Axios.get(url)
        .then(data => {
          success(data);
        })
        .catch(err => {
          error(err);
        });
      break;
    case "post":
      Axios.post(url, data)
        .then(data => {
          success(data);
        })
        .catch(err => {
          error(err);
        });
      break;
    default:
      return;
  }
}
function shuffleArray(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
let gifts = [
  "Naught! Cm'mon, have a coffee and come back",
  "Free, one week rapid prototype development",
  "5% discount on the first month billing*",
  "Free first-month upgrade to technical product manager*",
  "One week additional development time*",
  "3, 1 hour discussions with our senior architect*",
  "Two weeks additional development time*",
  "Two weeks additional development time*"
];
export {
  docHt,
  s3GlobalPath,
  colors,
  retrievePath,
  callAPI,
  get,
  set,
  getURL,
  updateLoc,
  shuffleArray,
  gifts
};
