import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Styles from '../app-style.js';
import {retrievePath} from '../services';
import PreloadImage from '../helpers/preloadimg'
class Banner extends Component {
  render(){
    const {fixed, style} = this.props;
    return (
      <Grid item container className={fixed?"fixed":""} style={{...Styles.banner, ...Styles.paddingL10, ...style}} alignItems="flex-start" justify="center" direction="column">
        <PreloadImage src={`${retrievePath()}${this.props.bg}`} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1}}/>
        {this.props.children}
        {/* <Grid item style={Styles.centerTxt}>          
            <Typography>Don't want to talk yet?</Typography>
            <div style={{color: colors.grey}}>
              <Typography>Scroll down</Typography>
            </div>
            <div className="material-icons" style={{fontSize: '4rem', color: colors.grey, lineHeight: '20px'}}>
              keyboard_arrow_down
            </div>
          
        </Grid> */}
        {/* <Grid item container xs={12} md={4} direction="column" alignItems="stretch" justify="flex-start" style={{...Styles.translucentContainer}}>
          <Grid item container>
            <Grid item>
              <Typography style={{...Styles.colorWhite}}>
                Got a requirement? Let's have a quick chat
              </Typography>
              <hr/>
            </Grid>
            <Grid item container style={{minHeight: '40vh'}} justify="center" alignItems="center">
              <Grid item xs={12}>
                <Signupform/>
              </Grid>                
            </Grid>
          </Grid>
        </Grid>         */}      
      </Grid>
    );
  }
}

export default Banner;
