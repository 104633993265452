import React, {Component} from 'react';
import { Grid, Typography } from '@material-ui/core';
import Banner from './sections/banner';
import Styles from './app-style';
import PreloadImage from './helpers/preloadimg';
import {retrievePath} from './services';
class How extends Component{
    render(){
        return (
            <React.Fragment>
                <Banner bg="how.jpg" style={Styles.marginBottom} fixed={true}>
                    <Grid item xs={12} style={{...Styles.centerTxt, ...{width: '100%'}}}>                        
                        <Typography variant="h1" style={{...Styles.centerTxt, ...Styles.marginBottom}}>How?</Typography>
                        <Typography variant="h2" style={Styles.centerTxt}><span style={Styles.colorPrimary}>Simple.</span> That's how</Typography>
                        <Typography variant="subtitle1" style={{...Styles.centerTxt, ...Styles.marginTop}}>Focus on building a great product. <br/>Leave your team building worry to us.</Typography>                        
                    </Grid>
                </Banner>
                <Grid item container alignItems="center" justify="center" direction="column" style={{...Styles.highZ, ...Styles.greyBG, ...Styles.padding10}}>
                    <Grid item style={{...Styles.marginBottom, ...Styles.centerTxt}}>
                        <Typography variant="h2" style={{...Styles.leftTxt}}>1 . 2 . 3 . Start your project in 3 easy steps</Typography>
                    </Grid>
                    <Grid item container spacing={4} style={Styles.marginBottom}>
                        <Grid item xs={4}>                            
                            <PreloadImage src={`${retrievePath()}ask.png`} style={{width: '90%', margin:'0 auto'}} alt="specify your resource requirements"/>
                        </Grid>
                        <Grid item xs={4}>
                            <PreloadImage src={`${retrievePath()}onboard.png`} style={{width: '90%', margin:'0 auto'}} alt="specify your resource requirements"/>
                        </Grid>
                        <Grid item xs={4}>
                            <PreloadImage src={`${retrievePath()}start.png`} style={{width: '90%', margin:'0 auto'}} alt="specify your resource requirements"/>
                        </Grid>
                    </Grid>
                    <Grid item container style={{...Styles.centerTxt}} spacing={4}>
                        <Grid item xs={4}>                            
                            <Typography variant="subtitle1">Ask</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">Onboard</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">Start!</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{...Styles.marginTop, ...Styles.centerTxt}} spacing={4}>
                        <Grid item xs={4}>                            
                            <Typography variant="subtitle2">
                                Specify the technology, resource count and expertise level
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2">We will present you with options right away from our pre-recruited pool of geeks</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2">Complete the onboarding formalities and start your project in under two days</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" justify="center" direction="column" style={{...Styles.highZ, ...Styles.whiteBG, ...Styles.padding10}}>
                    <Grid item style={{...Styles.marginBottom, ...{width: '50%'}}}>
                        <Typography variant="subtitle2" style={{...Styles.colorPrimary}}>
                            We follow a tight-knit process
                        </Typography>
                        <Typography variant="subtitle1" style={{...Styles.marginTop, ...Styles.marginBottom}}>
                            Above-expectation deliverables are just a by-product
                        </Typography>
                        <Typography variant="subtitle2">
                            A process perfected over the course of 12 months and still evolving because we know that your project deserves nothing less than beyond-perfect.
                            We cover every loop-hole, do constant quality checks and maintain a consistent two-way communication to ensure a great experience every single time.                        
                        </Typography>
                    </Grid>
                    <Grid item style={{...Styles.padding10, ...Styles.marginTop, ...Styles.centerTxt}}>
                        <Typography variant="subtitle2" style={{...Styles.marginTop, ...Styles.marginBottom}}>
                            <strong>Project initiation</strong>
                        </Typography>
                        <Typography variant="subtitle2">
                            They say well begun is half-done. We second that. <br/> Our initiation protocol ensures your project handles the current and future business-requirement with grace 
                        </Typography>
                    </Grid>
                    <Grid item style={{...Styles.centerTxt}}>
                        <PreloadImage src={`${retrievePath()}initiation.png`} style={{width: '90%', margin: '0 auto'}} alt="Personalized diet created by 1-to-1 coach"/>
                    </Grid>
                </Grid>
                <Grid item direction="column" style={{...Styles.highZ, ...Styles.whiteBG}}>
                    <Grid item container justify="center" style={{...Styles.centerTxt, ...Styles.padding10, ...{paddingTop: 0}}}>
                        <Typography variant="subtitle2" style={{...Styles.centerTxt, ...Styles.marginBottom}}>
                            <strong>Reporting and execution</strong>
                        </Typography>
                        <Typography variant="subtitle2" style={{...Styles.centerTxt, ...Styles.paddingLR}}>
                            Escalations are not fun. We at GeekSquad have maintained a rate of less than 0.1 escalation per month over the past one year of our existence. How do we do that? By maintaining a process that is an amalgamation of transparency, planning, continuous feedback and approval.
                        </Typography>                        
                    </Grid>
                    <Grid item container alignItems="center" style={{...Styles.marginBottom}}>
                        <Grid item xs={9}>
                            <PreloadImage src={`${retrievePath()}projectFlow.png`} style={{width: '100%', margin: '0 auto'}} alt="Personalized diet created by 1-to-1 coach"/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container style={{...Styles.highZ, ...Styles.whiteBG, ...Styles.padding5}} justify="center">
                    <Grid item style={{...{width: '50%'}, ...Styles.greyBG, ...Styles.padding5}}>
                        <Typography variant="subtitle1" style={Styles.marginBottom}>Already have a well-defined process?</Typography>
                        <Typography variant="subtitle2">Don't worry. We have mastered the art of blending-in. If you have a well-laid process, our team will follow it from day one. Our quality-check processes will act as a wrapper on top to ensure your happiness quotient stays high!</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}
export default How