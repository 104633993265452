import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Styles from '../app-style.js';
import { Fade, Typography, Slide } from '@material-ui/core';
import PreloadImage from '../helpers/preloadimg';
import {retrievePath, colors} from '../services';
class Yogabanner extends Component {
  
  render(){
    return (
      
        <Grid item container style={{...Styles.section02, ...Styles.highZ, ...Styles.whiteBG}} className="fixed" justify="space-evenly" alignItems="center">          
            <Grid item>
              <Fade in={this.props.section02} timeout={1200}>
                <PreloadImage src={`${retrievePath()}best_coders.png`} alt="Best reactjs developers" style={{height: '460px', margin: '0 auto'}}/>
              </Fade>
            </Grid>
            <Grid item >
              <Typography variant="subtitle1" style={{borderBottom: `1px solid ${colors.primary}`, padding: '10px 20px'}}>
                  Pre-recruited, quality coders ...
              </Typography>
            </Grid>
            <Slide direction="right" in={this.props.section02} timeout={1000} mountOnEnter unmountOnExit>
              <div style={{background: colors.greyBG, height: '460px', width: '100%', position: 'absolute', zIndex: '-1'}}>&nbsp;</div>
            </Slide>
              
        </Grid>
    );
  }
}

export default Yogabanner;
