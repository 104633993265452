import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Styles from "./app-style";
import { colors, retrievePath } from "./services";
import Technologies from "./technologies.json";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

class Tech extends Component {
  constructor() {
    super();
    this.technologies = Technologies;
    this.state = { technologies: this.technologies };
  }
  searchTechnology(e) {
    let str = e.target.value.toLowerCase();
    let prunedTechnologies = this.technologies.filter((el, indx) => {
      let masterStr = (el.name + el.desc).toLowerCase();
      return masterStr.indexOf(str) >= 0 ? true : false;
    });
    this.setState({ technologies: prunedTechnologies });
  }
  render() {
    let { technologies } = this.state;
    return (
      <Grid
        container
        style={{ ...Styles.fullHt, ...Styles.whiteBG, ...Styles.centerTxt }}
        alignItems='center'
        justify='flex-start'
        direction='column'
      >
        <Grid
          item
          container
          style={{
            ...Styles.imgWidth,
            ...Styles.padding5,
            ...Styles.paddingTop
          }}
          justify='center'
        >
          <Grid item xs={7}>
            <Typography variant='h2' style={Styles.marginBottom}>
              <strong>We are a focussed company</strong>
            </Typography>
            <Typography variant='subtitle1'>
              We are the masters of our niche. We deal with limited
              technologies. Pardon us for being boastful when we say - You can
              trust us with a blindfold on if your requirement falls in our
              domain.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction='column'
          style={{
            ...Styles.padding5,
            ...Styles.paddingTB,
            ...{ background: colors.primary }
          }}
        >
          <Grid item>
            <Typography variant='h2' style={Styles.colorBase}>
              Expertise
            </Typography>
            <Typography
              variant='subtitle1'
              style={{ ...Styles.marginTop, ...Styles.colorBase }}
            >
              Here are the technologies we work on
            </Typography>
          </Grid>
          <Grid item style={Styles.marginTop}>
            <input
              type='text'
              style={{
                background: colors.base,
                border: "none",
                maxWidth: "400px",
                borderRadius: "20px"
              }}
              placeholder='Search'
              onChange={e => this.searchTechnology(e)}
            />
            {/* <TextField style={{background: colors.base, borderRadius: '10px', border: 'none'}}/> */}
          </Grid>
          <Grid item style={Styles.marginTop}>
            <span className='material-icons' style={Styles.colorBase}>
              arrow_drop_down
            </span>
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{ ...Styles.marginBottom, ...{ marginTop: "-80px" } }}
          alignItems='center'
          justify='space-evenly'
        >
          {technologies.map(tech => {
            return (
              <Grid
                item
                xs={3}
                style={{
                  ...{
                    textAlign: "left",
                    margin: "2%",
                    marginTop: 0,
                    marginBottom: "100px"
                  }
                }}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia
                      style={{ height: "250px" }}
                      image={`${retrievePath()}${tech.image}.jpg`}
                      title='Contemplative Reptile'
                    />
                    <CardContent style={Styles.padding5}>
                      <Typography gutterBottom variant='h5' component='h2'>
                        {tech.name}
                      </Typography>
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        component='p'
                      >
                        {tech.desc}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions></CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

export default Tech;
