import React from "react";
import { colors, gifts } from "../services";
import Styles from "../app-style.js";
import { Grid, Typography, Divider, Button } from "@material-ui/core";
export default props => {
  let { score, resetGame, claimGift } = props;
  let getMsg = score => {
    switch (score) {
      case 1:
      case 2:
        return "Not bad at all!";
      case 3:
        return "Fair deal!";
      case 4:
        return "Good going!";
      case 5:
      case 6:
        return "Aha! Now that's some performance";
      case 7:
      case 8:
        return "That's a stellar performance!";
      default:
        return "Take another look, maybe?";
    }
  };
  return (
    <Grid
      item
      container
      direction='column'
      alignItems='center'
      justify='center'
      spacing={2}
      style={{ ...Styles.fullHtNoBG, ...{ width: "100%", overflow: "hidden" } }}
    >
      <Grid item>
        <Typography variant='subtitle1'>Your score is</Typography>
      </Grid>
      <Grid item>
        <Typography variant='h1'>{score}</Typography>
      </Grid>
      <Grid item>
        <Typography>{getMsg(score)}</Typography>
      </Grid>
      <Grid item style={{ width: "50%" }}>
        <Divider />
      </Grid>
      <Grid item>
        <span
          className='material-icons'
          style={{ fontSize: "3rem", color: colors.primary }}
        >
          redeem
        </span>
      </Grid>
      <Grid item>
        <Typography>And you have won ...</Typography>
      </Grid>
      <Grid item>
        <Typography variant='h2'>{gifts[score]}</Typography>
      </Grid>
      <Grid item container justify='center' alignItems='center' spacing={4}>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            startIcon={<span className='material-icons'>replay</span>}
            onClick={resetGame}
          >
            Play again
          </Button>
        </Grid>
        <Grid item>
          <Button variant='contained' color='primary' onClick={claimGift}>
            Claim gift
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
