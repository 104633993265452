import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Styles from "../app-style.js";
import PreloadImage from "../helpers/preloadimg.js";
import { retrievePath, colors } from "../services";
export default props => {
  let { geek, indx, matchGeek } = props;
  return (
    <Grid
      item
      xs={3}
      onClick={() => {
        matchGeek(geek, indx);
      }}
      className='flip-card'
    >
      <Grid item className={`flip-card-inner ${geek.flipped ? "flipped" : ""}`}>
        <Paper style={Styles.flipCard} elevation={1} className='flip-card-back'>
          <PreloadImage
            src={`${retrievePath()}geek/${geek.geek}.jpg`}
            alt='Geek '
            style={{ width: "100px", height: "100px" }}
          />
        </Paper>
        <Paper
          style={{
            ...{ background: colors.primary },
            ...Styles.flipCard
          }}
          elevation={1}
          className='flip-card-front'
        >
          <Grid
            container
            alignItems='center'
            justify='center'
            style={{ height: "100%" }}
          >
            <PreloadImage
              src={`${retrievePath()}logo_small.png`}
              alt='GeekSquad logo'
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
