import React, { Component } from "react";
import Banner from "./sections/banner";
import { Grid, Typography, Button } from "@material-ui/core";
import Styles from "./app-style";
import Signupform from "./signupform";
import { colors } from "./services";
import Faq from "./faq";
class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <Banner bg='contact.jpg'>
          <Grid container alignItems='flex-start' justify='flex-end'>
            <Grid item xs={6}>
              <Typography variant='h1' style={Styles.marginBottom}>
                We are all ears ...
              </Typography>
              <Typography variant='h2'>
                Reach out to us in 3 easy ways.
              </Typography>
            </Grid>
          </Grid>
        </Banner>
        <Grid
          item
          container
          direction='column'
          alignItems='flex-end'
          style={{
            ...{
              position: "absolute",
              right: "45%",
              top: "80%"
            },
            ...Styles.higherZ
          }}
        >
          <Grid
            item
            container
            alignItems='center'
            justify='center'
            style={{
              ...Styles.darkBG,
              ...Styles.contactAvatar
            }}
          >
            <Typography style={{ color: colors.base }}>Talk</Typography>
          </Grid>
          <Grid
            item
            container
            alignItems='stretch'
            justify='center'
            style={{ ...Styles.contactAvatar, ...{ minHeight: "150px" } }}
          >
            <div style={{ borderLeft: "1px solid #ccc" }}></div>
          </Grid>
          <Grid
            item
            style={{
              ...{
                borderBottom: "1px solid #ccc",
                borderRight: "1px solid #ccc",
                marginRight: "25px",
                padding: "5%"
              }
            }}
          >
            <Typography variant='subtitle1'>Contact us on</Typography>
            <Typography
              variant='h1'
              style={{ ...Styles.marginTop, ...Styles.colorPrimary }}
            >
              +91-8380047046
            </Typography>
            <Typography variant='subtitle2' style={Styles.marginTop}>
              Between 10:00 am - 6:00 pm, Mon-Fri
            </Typography>
          </Grid>

          {/* <Grid
              item
              container
              alignItems='center'
              justify='center'
              style={{
                ...Styles.darkBG,
                ...Styles.marginLR,
                ...Styles.contactAvatar
              }}
            >
              <Typography style={{ color: colors.base }}>Write</Typography>
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              justify='center'
              style={{
                ...Styles.darkBG,
                ...Styles.marginLR,
                ...Styles.contactAvatar
              }}
            >
              <Typography style={{ color: colors.base }}>Play</Typography>
            </Grid> */}
        </Grid>
        <Grid
          item
          container
          direction='column'
          alignItems='flex-end'
          style={{
            ...{
              position: "absolute",
              right: "30%",
              top: "80%"
            },
            ...Styles.highestZ
          }}
        >
          <Grid
            item
            container
            alignItems='center'
            justify='center'
            style={{
              ...Styles.darkBG,
              ...Styles.contactAvatar
            }}
          >
            <Typography style={{ color: colors.base }}>Write</Typography>
          </Grid>
          <Grid
            item
            container
            alignItems='stretch'
            justify='center'
            style={{ ...Styles.contactAvatar, ...{ minHeight: "450px" } }}
          >
            <div style={{ borderLeft: "1px solid #ccc" }}></div>
          </Grid>
          <Grid
            item
            style={{
              ...{
                borderBottom: "1px solid #ccc",
                borderRight: "1px solid #ccc",
                marginRight: "25px",
                padding: "5%"
              },
              ...Styles.highestZ
            }}
          >
            <Typography
              variant='subtitle1'
              style={{
                ...Styles.marginTop,
                ...Styles.marginBottom
              }}
            >
              ... or just fill the form below
            </Typography>
            <Signupform />
          </Grid>

          {/* <Grid
              item
              container
              alignItems='center'
              justify='center'
              style={{
                ...Styles.darkBG,
                ...Styles.marginLR,
                ...Styles.contactAvatar
              }}
            >
              <Typography style={{ color: colors.base }}>Write</Typography>
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              justify='center'
              style={{
                ...Styles.darkBG,
                ...Styles.marginLR,
                ...Styles.contactAvatar
              }}
            >
              <Typography style={{ color: colors.base }}>Play</Typography>
            </Grid> */}
        </Grid>
        <Grid
          item
          container
          direction='column'
          alignItems='flex-end'
          style={{
            ...{
              position: "absolute",
              right: "15%",
              top: "80%"
            },
            ...Styles.higherZ
          }}
        >
          <Grid
            item
            container
            alignItems='center'
            justify='center'
            style={{
              ...Styles.darkBG,
              ...Styles.contactAvatar
            }}
          >
            <Typography style={{ color: colors.base }}>Play</Typography>
          </Grid>
          <Grid
            item
            container
            alignItems='stretch'
            justify='center'
            style={{ ...Styles.contactAvatar, ...{ minHeight: "1350px" } }}
          >
            <div style={{ borderLeft: "1px solid #ccc" }}></div>
          </Grid>
          <Grid
            item
            style={{
              ...{
                borderBottom: "1px solid #ccc",
                borderRight: "1px solid #ccc",
                marginRight: "25px",
                padding: "5%"
              }
            }}
          >
            <Typography variant='subtitle1' style={Styles.marginBottom}>
              ... or play an interesting game <br /> & let us surprise you
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                console.log(this.props);
                this.props.history.push("game");
              }}
            >
              <Typography variant='subtitle1'>Play now!</Typography>
            </Button>
          </Grid>

          {/* <Grid
              item
              container
              alignItems='center'
              justify='center'
              style={{
                ...Styles.darkBG,
                ...Styles.marginLR,
                ...Styles.contactAvatar
              }}
            >
              <Typography style={{ color: colors.base }}>Write</Typography>
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              justify='center'
              style={{
                ...Styles.darkBG,
                ...Styles.marginLR,
                ...Styles.contactAvatar
              }}
            >
              <Typography style={{ color: colors.base }}>Play</Typography>
            </Grid> */}
        </Grid>
        <Grid
          item
          container
          direction='column'
          alignItems='flex-end'
          style={{
            ...{
              minHeight: `${window.innerHeight + 1000}px`
            },
            ...Styles.greyBG,
            ...Styles.padding5
          }}
        ></Grid>
        <Grid item>
          <Faq />
        </Grid>
        {/* <Grid
          item
          container
          direction='column'
          alignItems='flex-end'
          style={{ ...Styles.fullHt, ...Styles.greyBG, ...Styles.padding5 }}
        >
          <Grid
            item
            style={{
              ...Styles.marginBottom,
              ...Styles.marginTop,
              ...Styles.padding5,
              ...{
                marginRight: `${document.documentElement.offsetWidth * 0.3 +
                  110}px`,
                borderBottom: `1px solid ${colors.grey}`
              }
            }}
          >
            <Typography variant='h1'>Contact us on</Typography>
            <Typography variant='h2' style={Styles.marginTop}>
              +91-9999999999
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              ...Styles.marginBottom,
              ...Styles.marginTop,
              ...Styles.padding5,
              ...{
                marginRight: `${document.documentElement.offsetWidth * 0.3 -
                  55}px`,
                borderBottom: `1px solid ${colors.grey}`
              }
            }}
          >
            <Typography variant='h1'>Contact us on</Typography>
            <Typography variant='h2'>+91-9999999999</Typography>
          </Grid>
          <Grid
            item
            style={{
              ...Styles.marginBottom,
              ...Styles.marginTop,
              ...Styles.padding5,
              ...{
                marginRight: `${document.documentElement.offsetWidth * 0.3 -
                  220}px`,
                borderBottom: `1px solid ${colors.grey}`
              }
            }}
          >
            <Typography variant='h1'>Contact us on</Typography>
            <Typography variant='h2'>+91-9999999999</Typography>
          </Grid>
        </Grid> */}
      </React.Fragment>
    );
  }
}
export default Contact;
