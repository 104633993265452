import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Styles from '../app-style.js';
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import {colors} from '../services';
// import PreloadImage from '../helpers/preloadimg'
class Homefood extends Component {
  render(){
    return (
      <Grid item container style={{...Styles.highZ, ...Styles.fullHt}} justify="space-evenly" alignItems="center" className="top">
        <Grid item xs={5}>
          <Typography variant="h1" style={Styles.marginBottom}>
            We seek your love ...
          </Typography>
          <Typography variant="subtitle1">
            Starting of our engagement with you is just the beginning. We believe in building a lasting relation. To ensure this, we request for frequent feedback from you and provide our geeks necessary support from our architects. <span style={{color: colors.primary}}>We keep a close tab on our team's attitude, aptitude and efficiency.</span>
          </Typography>
        </Grid>
        {/* <Grid item xs={5}>
          <PreloadImage src={`${retrievePath()}recipe.jpg`} alt="Simple, macro-calculated recipes" style={Styles.imgWidth}/>
        </Grid> */}
        <Grid item xs={5}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className="material-icons">add</span>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">Onboarding call with architects</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="subtitle2">
                Our architect attends every project onboarding meeting along with the developers. He conducts a post-brief meeting with the team to ensure they grasp the requirements thoroughly and clears any doubts.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className="material-icons">add</span>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">Weekly feedback request</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="subtitle2">
                We send the project SPOC a weekly email for providing feedback. Feedback form does not take more than 10 minutes and covers all aspects from professionalism, time-sheet approval to expertise.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className="material-icons">add</span>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">Support from architects</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="subtitle2">
                Every geek who is deployed is assigned a super-geek. Super geeks are highly experienced architects who the team member can reach out to for any support or any blocker. It saves you countless development hours. Best part? This comes bundled without any additional cost on your end.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className="material-icons">add</span>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">Swap a geek</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="subtitle2">
                In the never-before event, if you are not happy with the assigned geek, we swap him/her with another one following a two-weeks notice. To boast a little, this has never happened to us before.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className="material-icons">add</span>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">Daily timesheet and task tracking</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="subtitle2">
                Well-planned timesheets to ensure the tasks are clearly laid out and tracked transparently with all stakeholders in loop. The sheets are shared with the project SPOC to make sure we receive a progressive feedback.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<span className="material-icons">add</span>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">Quick escalation resolution</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="subtitle2">
                In the rare case, if there is an escalation, your relationship manager will make sure it is resolved on priority. No passing the puck. Plain, simple, direct dialogs and long-term fix. 
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export default Homefood;
