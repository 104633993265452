import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Styles from '../app-style.js';
import {Typography} from '@material-ui/core';
import PreloadImage from '../helpers/preloadimg';
import {retrievePath, colors} from '../services';
import { Slide } from '@material-ui/core';
import {
  NavLink
} from "react-router-dom";

class Header extends Component {
  scrollPos = 0;
  constructor(){
    super();
    this.state={
      showHeader: true
    }
  }
  handleScroll(e){
    let scrollTop = window.scrollY;
    if(scrollTop<this.scrollPos)
    {
      if(!this.state.showHeader)
      {
        this.setState({showHeader: true});
      }
    }
    else{
      if(scrollTop>=80 && this.state.showHeader)
      {
        this.setState({showHeader: false})
      }
    }
    this.scrollPos = scrollTop;
  }
  componentDidMount() {
    // updateLoc(this.cb);
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll.bind(this))
  }
  render(){
    const {showHeader} = this.state;
    return (
      <React.Fragment>
      <Slide in={!showHeader} direction="down">
        <Grid container justify="center" alignItems="center" style={{...Styles.smallLogo, ...Styles.higherZ, ...{background: colors.primary}}}>
          <PreloadImage alt="GeekSquad - Hire awesome Javascript, reactjs, Angularjs, Nodejs developers and architects" src={`${retrievePath()}logo_small.png`}/>
          {/* <Grid item style={{width: '20px'}}>
            <span className="material-icons" style={{fontSize: '10px', color: colors.secondary}}>
              arrow_back_ios
            </span>
            <span className="material-icons" style={{fontSize: '10px', color: colors.secondary}}>
              arrow_forward_ios
            </span>
          </Grid> */}
          
        </Grid>
      </Slide>
      <Slide in={showHeader} direction="down">
        <Grid item container style={{...Styles.header}} className="header" justify="space-between">
          <Grid item >
            <Grid item>
              <NavLink to="/">
                <PreloadImage alt="GeekSquad - Hire awesome Javascript, reactjs, Angularjs, Nodejs developers and architects" style={{width:'150px'}} src={`${retrievePath()}logo.png`}/>
              </NavLink>
            </Grid>
            
          </Grid>
          <Grid item container justify="flex-end" alignItems="center" xs={8} spacing={6}>          
              {/* <Typography variant="subtitle2" style={Styles.colorWhite}>Whatsapp <span style={{color: colors.primary}}>'RENTATEAM'</span> to <span style={{color: colors.primary}}>+91-9108005232</span></Typography> */}
              <Grid item>
                <NavLink to="why" style={{color: colors.secondary}} activeStyle={Styles.activeLink}>
                  <Typography variant="subtitle2">why</Typography>
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink to="how" activeStyle={Styles.activeLink} style={{color: colors.secondary}}>
                  {<Typography variant="subtitle2">how</Typography>}
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink to="tech" style={{color: colors.secondary}} activeStyle={Styles.activeLink}>
                  <Typography variant="subtitle2">what</Typography>
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink to="contact" style={{color: colors.grey}} activeStyle={Styles.activeLink}>
                  <span className="material-icons">
                    chat_bubble_outline
                  </span>
                </NavLink>
              </Grid>
          </Grid>
        </Grid>
      </Slide>
      </React.Fragment>
    );
  }
}

export default Header;
