import { createMuiTheme } from '@material-ui/core/styles';
import OpensansRegular from './fonts/AktivGrotesk-Regular.woff';
import OpensansBold from './fonts/AktivGrotesk-Medium.woff';
import OpensansExtraBold from './fonts/AktivGrotesk-Bold.woff';
import OpensansLight from './fonts/AktivGrotesk-Thin.woff';
import {colors} from './services';

const opensansLight = {
    fontFamily: 'Opensans-Thin',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `
      local('Opensans'),
      local('Opensans-Thin'),
      url(${OpensansLight}) format('woff')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };
const opensans = {
  fontFamily: 'Opensans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Opensans'),
    local('Opensans'),
    url(${OpensansRegular}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const opensansExtraBold = {
    fontFamily: 'Opensans-Heavy',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `
      local('Opensans'),
      local('Opensans-Heavy'),
      url(${OpensansExtraBold}) format('woff')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };
const opensansBold = {
    fontFamily: 'Opensans-Bold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `
      local('Opensans'),
      local('Opensans-Bold'),
      url(${OpensansBold}) format('woff')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.primary
        },
        secondary:{
          main: colors.secondary
        }
      },
    typography: {
        fontFamily: 'Opensans, Arial',
        root: {
            color: colors.secondary
        },
        p:{
            fontSize: '1rem',
        },
        h1:{
            fontSize: '2.0rem',
            fontFamily: 'Opensans-Thin',
            fontWeight: 'strong',
            // letterSpacing: '1.5px',
            lineHeight: '1.3em',
            color:  colors.grey
        },
        h2:{
            fontSize: '2.0rem',
            fontFamily:'Opensans-Thin',
            lineHeight: '1.3em',
            color:  colors.grey
        },
        h3:{
            fontSize: '1.6rem',
        },
        subtitle1:{
            fontSize: '1.5rem',
            lineHeight: '1.5em',
            fontFamily:'Opensans-Thin'
        },
        subtitle2:{
          fontSize: '1.2rem',
          fontFamily:'Opensans-Thin',
          lineHeight: '1.5em'
      }
    },
    MuiButton:{
      root:{
        borderRadius: '50px',
      }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
            '@font-face': [opensans, opensansBold, opensansExtraBold, opensansLight],
            },
        },
        MuiButton: {
          // Name of the rule
          root: {
            //borderRadius: '20px',
            textTransform:'none',
          },
          outlined:{
            padding: '8px 20px'
          },
          contained:{
            padding: '8px 20px'
          },
          containedPrimary:{
            color: colors.base
          }
        },
        MuiSelect:{
          root: {color: '#c0c0c0'},
        },
        MuiList:{
          root:{background:'none'}
        },
        MuiExpansionPanel:{
          root:{
            background: 'none',
            boxShadow: 'none',
            border: 'none'
          }
        },
        MuiExpansionPanelSummary:{
          root: {
            background: 'none',
            color: colors.secondary,
            boxShadow: 'none',
          },
          expandIcon:{
            color: colors.primary,
            "&$expanded":{transform: 'rotate(135deg)'}
          }
        },
        MuiExpansionPanelDetails:{
          root: {
            background: 'none',
            boxShadow: 'none',
            border: `none`
          }
        }
    },
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The default props to change
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
})

export default theme;