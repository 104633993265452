import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Styles from '../app-style.js';
import { Typography} from '@material-ui/core';
import {colors, retrievePath} from '../services';
import PreloadImage from '../helpers/preloadimg'
class Homeworkout extends Component {
  constructor(props){
    super(props);
    this.state={
      videoPlaying:false
    }
  }
  render(){
    // let {videoPlaying} = this.state;
    return (
      <Grid container item style={{...Styles.highZ, ...Styles.fullHt, ...Styles.whiteBG}} direction="column" alignItems="center" justify="center" className="top">
        <Grid item  style={{...Styles.marginBottom}}>
          <PreloadImage src={`${retrievePath()}beerHappy.png`} alt="Hire and fire at will" style={{maxWidth: '150px'}}/>
        </Grid>
        <Grid item container xs={8} alignItems="center" justify="center" direction="column" style={Styles.centerTxt}>
          <Typography variant="h1">
              Hire-Fire-Hire 
            </Typography>
            <Typography variant="subtitle2" style={Styles.marginBottom}>
              No hard feelings 
            </Typography>                
            <Typography variant="subtitle1" style={Styles.marginTop}>
              Save money by releasing one or more (or all) team members when your work is done or your project is stablized. Hire them again whenever you need them. No delays. <span style={Styles.colorPrimary}>No minimum commitment*</span>
            </Typography>                
            <Typography variant="subtitle2">
              *We wouldn't mind you buying us a beer though if you love our thoroughly professional, no fuss attitude.
            </Typography>
        </Grid>
        {/* <Grid container item xs={5} style={{...{padding:'40px', position: 'relative'}}} alignItems="center" justify="center" direction="column">
          <Grid item xs={10} style={{position: 'relative'}}>
            <video width="300" controls={videoPlaying} ref="vidRef">
              <source src={`${retrievePath()}homewo.mp4`} type="video/mp4"/>
              Your browser does not support HTML5 video.
            </video> 
            {!videoPlaying && <div onClick={()=>{this.refs.vidRef.play(); this.setState({videoPlaying: true})}} style={{...Styles.highZ, ...{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}}><PreloadImage src={`${retrievePath()}videoframe.jpg`} alt="Start your day with meditation or yoga" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1}}/></div>
            }                 
          </Grid>
        </Grid> */}
      </Grid>
    );
  }
}

export default Homeworkout;
