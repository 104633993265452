import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Styles from "../app-style.js";
import { Typography, Button } from "@material-ui/core";
import { colors, retrievePath } from "../services";
import PreloadImage from "../helpers/preloadimg";
class Fun extends Component {
  render() {
    return (
      <Grid
        item
        container
        style={{
          ...Styles.highZ,
          ...Styles.fullHt,
          ...Styles.whiteBG,
          ...Styles.fixed
        }}
        justify='space-evenly'
        alignItems='center'
        className='top'
      >
        <Grid item xs={6} style={Styles.padding5}>
          <Typography variant='h1' style={Styles.marginBottom}>
            Completely managed remote team
          </Typography>
          <Typography
            variant='subtitle1'
            style={{ ...{ color: colors.grey }, ...Styles.marginBottom }}
          >
            Remote teams are great with only one exception - managing a team
            spread across geographies can get challenging. Not with GeekSquad.
            Presenting -{" "}
            <span style={Styles.colorPrimary}>
              Completely managed remote teams
            </span>
            . We take away all the hassles of management by deploying{" "}
            <span style={Styles.colorPrimary}> 'technical managers'</span> with
            every project. These are our{" "}
            <span style={Styles.colorPrimary}>
              senior geeks trained in project management
            </span>
            . They write great codes for your project and act as a SPOC for your
            in-house project manager. They manage the GeekSquad team and ensure
            timely deliveries.
          </Typography>
          {/* <Button
            variant='contained'
            color='secondary'
            onClick={this.props.showDetails}
          >
            <Typography variant='subtitle2' style={{ paddingRight: "10px" }}>
              Know more
            </Typography>
            <span className='material-icons' style={{ color: colors.base }}>
              arrow_forward
            </span>
          </Button> */}
        </Grid>
        <Grid item xs={5} style={{ textAlign: "right" }}>
          {/* <PreloadImage src={`${retrievePath()}zumba.png`} alt="Simple, macro-calculated recipes" style={{width: '80%'}}/> */}
          <PreloadImage
            src={`${retrievePath()}managerTeam.png`}
            alt='Personalized diet created by 1-to-1 coach'
            style={{ maxWidth: "90%" }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Fun;
