import React from "react";
import { colors } from "../services";
import { Grid, Typography } from "@material-ui/core";
export default props => {
  let { timeRemaining, score } = props;
  return (
    <Grid
      item
      container
      direction='column'
      justify='center'
      alignItems='center'
      spacing={2}
      style={{
        position: "absolute",
        bottom: "50px",
        left: "10px",
        width: "10%",
        border: `3px solid ${colors.primary}`
      }}
    >
      <Grid
        item
        container
        justify='center'
        alignItems='center'
        direction='column'
      >
        <Typography variant='h1'>{score}</Typography>
        <Typography>Your score</Typography>
      </Grid>
      <Grid
        item
        container
        justify='center'
        alignItems='center'
        direction='column'
      >
        <Typography variant='h1'>{timeRemaining}</Typography>
        <Typography>Time remaining</Typography>
      </Grid>
    </Grid>
  );
};
