import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Styles from '../app-style.js';
import { Typography, Button, Icon} from '@material-ui/core';
import {colors, retrievePath} from '../services';
import PreloadImage from '../helpers/preloadimg'
class Diet extends Component {
  render(){
    return (
      <Grid item container style={{...Styles.highZ, ...Styles.fullHt, ...Styles.whiteBG}} justify="space-evenly" alignItems="stretch" className="top">
        <Grid item container justify="center" alignItems="center" xs={6} style={{...Styles.greyBG,...{textAlign: 'right'}}}>
          <Grid item>
            <PreloadImage src={`${retrievePath()}peace.png`} alt="Personalized diet created by 1-to-1 coach" style={{maxWidth: '90%'}}/>
          </Grid>
        </Grid>
        <Grid item container justify="center" alignItems="center" xs={6}>
          <Grid item xs={7}>
            <Typography variant="h1" style={Styles.marginBottom}>
              Peace of mind
            </Typography>
            <Typography variant="subtitle1">
              Follow agile? No problem. Requirements changing mid-way? No problem. <br/>Extended working hours required around release? We understand. This is your in-house team. You pay a <span style={Styles.colorPrimary}>fixed monthly professional fee</span>. No unexpected surprises.
            </Typography>
          </Grid>
          {/* <Button variant="outlined" color="secondary" startIcon={<Icon>add_box</Icon>} style={{marginTop: '20px'}} onClick={this.props.showDetails}>
            <Typography variant="subtitle2">
              How does your coach support you
            </Typography>
          </Button> */}
        </Grid>              
      </Grid>
    );
  }
}

export default Diet;
