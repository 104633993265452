import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import Styles from "../app-style.js";
import { colors, shuffleArray, gifts } from "../services";
import Geeks from "./Geeks.js";
import Score from "./Score.js";
import Intro from "./Intro.js";
import Gifts from "./Gifts.js";
import Signupform from "../signupform";
class Game extends Component {
  constructor() {
    super();
    this.geeks = [
      "geek01",
      "geek02",
      "geek03",
      "geek04",
      "geek05",
      "geek06",
      "geek07",
      "geek08"
    ];
    this.state = {
      listOfGeeks: [],
      allFlipped: true,
      selectedGeek: {},
      timeRemaining: 10,
      score: 0,
      claimGift: false,
      gift: null
    };
    this.peekaboo = 3;
  }

  componentDidMount() {
    this.initiateGame();
  }
  initiateGame() {
    let doubleGeeks = [...this.geeks, ...this.geeks];
    let randomGeeks = shuffleArray(doubleGeeks);
    randomGeeks.map((geek, indx) => {
      randomGeeks[indx] = {};
      randomGeeks[indx].geek = geek;
      randomGeeks[indx].flipped = true;
      return false;
    });
    this.setState({ listOfGeeks: randomGeeks });
    setTimeout(() => {
      randomGeeks.map((geek, indx) => {
        randomGeeks[indx].flipped = false;
        return false;
      });
      this.setState({ listOfGeeks: randomGeeks, allFlipped: false });
    }, this.peekaboo * 1000);
    let timer = setInterval(this.setRemainingTime, 1000);
    this.setState({ timer: timer });
  }
  setRemainingTime = () => {
    let { timeRemaining } = this.state;
    if (timeRemaining === 0) {
      clearInterval(this.state.timer);
      return;
    }
    this.setState({ timeRemaining: timeRemaining - 1 });
  };
  setFlipped(indx, status) {
    let tempArr = this.state.listOfGeeks.slice(0);
    tempArr[indx].flipped = status;
    this.setState({ listOfGeeks: tempArr });
  }
  matchGeek = (geek, indx) => {
    let { selectedGeek, score } = this.state;
    if (indx === selectedGeek.indx) return;
    if (selectedGeek.geek) {
      if (geek.geek === selectedGeek.geek) {
        this.setFlipped(indx, true);
        this.setState({ score: score + 1 });
      } else {
        this.setFlipped(selectedGeek.indx, false);
      }
      this.setState({ selectedGeek: {} });
    } else {
      this.setState({ selectedGeek: { geek: geek.geek, indx: indx } });
      this.setFlipped(indx, true);
    }
  };
  resetGame = () => {
    this.setState({
      listOfGeeks: [],
      allFlipped: true,
      selectedGeek: {},
      timeRemaining: 10,
      score: 0,
      claimGift: false
    });
    this.initiateGame();
  };
  claimGift = () => {
    this.setState({ claimGift: true, gift: gifts[this.state.score] });
  };
  render() {
    let {
      listOfGeeks,
      allFlipped,
      timeRemaining,
      score,
      claimGift,
      gift
    } = this.state;
    return (
      <Grid
        container
        style={{
          ...Styles.marginTop,
          ...Styles.fullHtNoBG
        }}
        alignItems='center'
        justify='center'
      >
        {allFlipped && <Intro />}
        {timeRemaining > 0 && !claimGift && (
          <React.Fragment>
            <Grid
              item
              container
              style={{ width: "600px", height: "600px" }}
              alignItems='center'
              justify='center'
              spacing={2}
            >
              {listOfGeeks.map((geek, indx) => (
                <Geeks geek={geek} indx={indx} matchGeek={this.matchGeek} />
              ))}
            </Grid>
            <Score score={score} timeRemaining={timeRemaining} />
          </React.Fragment>
        )}
        {timeRemaining === 0 && !claimGift && (
          <Gifts
            score={score}
            resetGame={this.resetGame}
            claimGift={this.claimGift}
          />
        )}
        {claimGift && (
          <Grid
            direction='column'
            alignItems='center'
            style={{
              width: "30%",
              minWidth: "200px",
              margin: "14vh auto"
            }}
          >
            <Grid item style={Styles.marginBottom}>
              <Typography variant='h2'>Claim your gift!</Typography>
            </Grid>
            <Grid item style={Styles.marginBottom}>
              <Typography variant='subtitle2'>
                Just fill the form below and we will contact you to explain how
                to redeem your gift
              </Typography>
            </Grid>
            <Grid item>
              <Signupform gift={gift} />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
export default Game;
