import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import { Fade, Grid } from "@material-ui/core";
import Styles from "../app-style.js";
import { colors } from "../services";
class Coachingplan extends Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: props.showPopup, currentScroll: 0 };
  }
  freezeScroll(val) {
    console.log("freezing", val);
    document.body.style.overflow = val ? "hidden" : "scroll";
  }
  shouldComponentUpdate(nxtProps, nxtState) {
    console.log(nxtProps, nxtState);
    return !(this.props === nxtProps && this.state === nxtState);
  }
  componentDidMount() {
    this.showDetails();
  }
  showDetails() {
    this.setState({ currentScroll: window.scrollY });
    this.freezeScroll(true);
  }
  hidePopup() {
    console.log("hide");
    let { currentScroll } = this.state;
    let { hidePopup } = this.props;
    if (hidePopup) hidePopup();
    this.freezeScroll(false);
    this.setState({ showPopup: false }, () => {
      document.documentElement.scrollTop = currentScroll;
      document.body.scrollTop = currentScroll;
    });
  }
  render() {
    let { showPopup } = this.state;
    return (
      <Fade in={showPopup} timeout={200}>
        <Grid
          item
          container
          style={{
            ...Styles.highestZ,
            ...{
              position: "fixed",
              top: 0,
              left: 0,
              height: `100vh`,
              padding: "5%",
              paddingTop: "5vh",
              overflowY: showPopup ? "scroll" : "hidden",
              width: "100%",
              background: colors.base
            }
          }}
          alignItems='flex-start'
          justify='center'
        >
          <div
            style={{
              position: "sticky",
              left: "48%",
              top: "70px",
              zIndex: 100000
            }}
          >
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                this.hidePopup();
              }}
              style={{
                padding: "10px",
                borderRadius: "20px",
                height: "auto",
                width: "auto",
                minWidth: 0
              }}
            >
              <span className='material-icons' style={{ fontSize: "1rem" }}>
                close
              </span>
            </Button>
          </div>
          {this.props.children}
        </Grid>
      </Fade>
    );
  }
}

export default Coachingplan;
